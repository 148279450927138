body {
  margin: 0;
  font-family: "Soleil", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: "Soleil";
  src: local("Soleil-Bold"),
  url("./fonts/soleil/hinted-Soleil-Bold.woff2") format("woff2"),
  url("./fonts/soleil/hinted-Soleil-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Soleil";
  src: local("Soleil-Regular"),
  url("./fonts/soleil/hinted-Soleil-Regular.woff2") format("woff2"),
  url("./fonts/soleil/hinted-Soleil-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Soleil";
  src: local("Soleil-Light"),
  url("./fonts/soleil/hinted-Soleil-Light.woff2") format("woff2"),
  url("./fonts/soleil/hinted-Soleil-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Soleil";
  src: local("Soleil-Book"),
  url("./fonts/soleil/hinted-Soleil-Book.woff2") format("woff2"),
  url("./fonts/soleil/hinted-Soleil-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
